<script lang='ts'>
  import Logo from "@component/patterns/Shield.svelte";
  import Menu from "@component/patterns/Menu.svelte";
  import '@styles/app.scss';

</script>

<svelte:head>
  <title>Practical Solutions</title>
</svelte:head>


<header class="container py-3 py-lg-5">
  <div class="d-flex flex-wrap justify-content-between">
    <a href="/" class="logo">
      <Logo company="practical" claim="solutions"/>
    </a>
    <Menu/>
  </div>
</header>

<slot />


<footer class="text-center mb-4">
  &copy; Practical AT | <a href="/contact">Impressum</a> | <a href="/contact">Kontakt</a>
</footer>
