<script lang="ts">
  import PhoneIcon from "@component/patterns/PhoneIcon.svelte";
  import {page} from "$app/stores";

  const menu = [
    { text: 'Web Applications', href: '/web-apps'},
    { text: 'IT Security', href: '/it-security'},
    { text: 'IT Systems', href: '/it-systems'},
    { text: 'Datenschutz', href: '/dsgvo'},
    { text: 'Kontakt', href: '/contact', icon: true}
  ]

  let isActive = false;
  function toggleMenu() { isActive = !isActive }
  function closeMenu() { isActive = false }
</script>

<button class="hamburger hamburger--collapse d-block d-lg-none" class:is-active={isActive} type="button" on:click="{toggleMenu}">
  <span class="sr-only">Navigation</span>
  <span class="hamburger-box">
    <span class="hamburger-inner"></span>
  </span>
</button>
<nav class="menu align-items-end" class:menu--open={isActive}>
  {#each menu as {text, href, icon}}
    <a data-sveltekit-prefetch {href} class="menu__item" class:active={$page.url.pathname === href} on:click="{closeMenu}">
      {#if icon }<PhoneIcon/>{/if}{text}
    </a>
  {/each}
</nav>

<style lang="scss">
  @import "custom-hamburgers";
</style>
